import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Action } from '../../../../../../shared/enums/action.enum'
import { CaseListComponent } from '../../../abacus/components/case-list.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { YieldType } from '../../../abacus/enums/yield-type.enum'
import { Filter } from '../../../abacus/interfaces/filter.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../../abacus/interfaces/yield.interface'
import { AuthService } from '../../../abacus/services/auth.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FilterService } from '../../../abacus/services/filter.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseListTemplate } from '../../../abacus/templates/case-list.template'
import { errorDefinition } from '../error.definition'

@Component({
  selector: 'app-error-list',
  template: caseListTemplate
})
export class ErrorListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = errorDefinition
  yields: Yield[] = [
    {
      label: 'image',
      property: 'image',
      className: 'is-hidden-touch',
      type: YieldType.Download,
      disableOrderBy: true
    },
    {
      label: 'date',
      property: 'createdAt',
      type: YieldType.Datetime
    },
    {
      label: 'action',
      property: 'action'
    },
    {
      label: 'User',
      property: 'user.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'site',
      property: 'user.site.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'code',
      property: 'code'
    },
    {
      label: 'detection UUID',
      property: 'detectionId'
    },
    {
      label: 'message',
      property: 'errorMessage',
      icon: 'icon-message-circle',
      type: YieldType.Icon
    }
  ]

  filters: Filter[] = [
    {
      label: 'Date',
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      className: 'is-6',
      inputType: InputType.DateRange
    },
    {
      label: 'Site',
      className: 'is-3 is-hidden-mobile',
      property: 'siteIds',
      placeholder: 'All sites',
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('sites')
    },
    {
      label: 'Action',
      className: 'is-3 is-hidden-mobile',
      property: 'action',
      placeholder: 'All actions',
      inputType: InputType.Select,
      selectOptions: Object.keys(Action).map((key) => ({
        label: key,
        value: Action[key as keyof typeof Action]
      }))
    },
    {
      label: 'Search errors',
      className: 'is-6',
      inputType: InputType.MultiSearch,
      placeholder: 'Search an error with UUID, message or code...',
      searchResources: [errorDefinition],
      properties: {
        errorIds: 'errorIds'
      }
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService,
    private componentResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
