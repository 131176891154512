import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { Reusability } from '../../../../../../shared/enums/reusability.enum'
import { CaseDetailComponent } from '../../../abacus/components/case-detail.component'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { evaluationDefinition } from '../evaluation.definition'

@Component({
  templateUrl: './evaluation-detail.component.html',
  styleUrls: ['./evaluation-detail.component.scss']
})
export class EvaluationDetailComponent
  extends CaseDetailComponent
  implements OnInit
{
  Reusability = Reusability
  definition: ResourceDefinition = evaluationDefinition

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    await this.initDetailView()
  }
}
