<nav
  class="pagination is-centered my-5 is-rounded"
  role="navigation"
  aria-label="pagination"
  *ngIf="paginator.lastPage > 1"
>
  <!-- Page list -->
  <ul class="pagination-list">
    <li *ngIf="pageArray[0] > 1">
      <span class="pagination-ellipsis">&hellip;</span>
    </li>

    <li *ngFor="let pageNumber of pageArray">
      <a
        (click)="goToPage(pageNumber)"
        class="pagination-link"
        [ngClass]="{ 'is-current': paginator.currentPage == pageNumber }"
        duration="400"
        >{{ pageNumber }}</a
      >
    </li>

    <li *ngIf="pageArray[pageArray.length - 1] < paginator.lastPage">
      <span class="pagination-ellipsis">&hellip;</span>
    </li>
  </ul>
</nav>
