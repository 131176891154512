<ng-container *ngIf="!isLogin && !isCamera">
  <case-top-menu
    [ngClass]="{ 'is-collapsed': isCollapsed }"
    [isStaging]="isStaging"
    *ngIf="!isTouchResolution"
  ></case-top-menu>
  <app-touch-menu
    *ngIf="isTouchResolution"
    [menuItems]="menuItems"
  ></app-touch-menu>
</ng-container>

<case-flash-message></case-flash-message>

<!--  Main Content -->
<div class="wrapper" [ngClass]="{ 'is-collapsed': isCollapsed }">
  <div class="columns">
    <aside
      #menuList
      class="column aside menu px-0"
      *ngIf="!isLogin && !isTouchResolution"
    >
      <case-side-menu
        [path]="path"
        [isCollapsed]="isCollapsed"
        [menuItems]="menuItems"
      ></case-side-menu>
    </aside>
    <div class="column main">
      <main>
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>

<case-footer></case-footer>
