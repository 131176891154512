import { Component, OnInit } from '@angular/core'
import { AuthService } from 'src/app/abacus/services/auth.service'
import { User } from '../../abacus/interfaces/resources/user.interface'
import { BreadcrumbService } from '../../abacus/services/breadcrumb.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentUser: User

  constructor(
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.breadcrumbService.breadcrumbLinks.next([
      {
        label: `Accueil`
      }
    ])
    this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })
  }
}
