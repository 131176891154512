import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { CameraGuard } from '../../guards/camera.guard'
import { ControlCreateEditComponent } from './control-create-edit/control-create-edit.component'
import { ControlDetailComponent } from './control-detail/control-detail.component'
import { ControlListComponent } from './control-list/control-list.component'
import { GoToControlComponent } from './go-to-control/go-to-control.component'

export const controlRoutes: Route[] = [
  {
    path: 'controls',
    component: ControlListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseControls', 'browseOwnControls']
    }
  },
  {
    path: 'controls/create',
    component: ControlCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard, CameraGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addControls'
    }
  },
  {
    path: 'controls/:id',
    component: ControlDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readControls', 'readOwnControls']
    }
  },

  {
    path: 'go-to-control',
    component: GoToControlComponent,
    canActivate: [AuthGuard, PermissionGuard]
  }
]
