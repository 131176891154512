<div class="field">
  <input
    id="switch-{{ uniqueId }}"
    type="checkbox"
    name="switch-{{ uniqueId }}"
    class="switch is-rounded is-success"
    [checked]="value"
    (click)="$event.preventDefault()"
  />
  <label for="switch-{{ uniqueId }}">
    <ng-container *ngIf="displayValue">
      {{ displayValue }}
    </ng-container>
  </label>
</div>
