import { ActionType } from 'src/app/abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const controlDefinition: ResourceDefinition = {
  title: 'Controls',
  nameSingular: 'control',
  namePlural: 'controls',
  className: 'Control',
  mainIdentifier: 'reference',
  slug: 'controls',
  path: 'controls',
  icon: 'icon-file-text',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: null,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Delete',
      permission: 'deleteControls',
      action: (control) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: control,
          definition: controlDefinition
        }
      })
    }
  ]
}
