<div class="container container-result" *ngIf="item">
  <div
    class="card is-radiusless is-fullheight"
    [ngClass]="{
      'has-background-danger': item.screenType === ScreenType.CCFL,
      'has-background-warning': item.screenType === ScreenType.Unknown,
      'has-background-success':
        item.screenType !== ScreenType.CCFL &&
        item.screenType !== ScreenType.Unknown
    }"
  >
    <div class="card-content" *ngIf="item.screenType === ScreenType.Unknown">
      <div class="content mt-10 mt-0-small-mobile">
        <div class="button is-circle is-dark m-auto">
          <i class="icon icon-codepen"></i>
        </div>
        <h1
          class="title is-1 has-text-weight-bold has-text-centered mt-4 mb-10 mb-0-small-mobile"
        >
          <ng-container *ngIf="isQuickFlow">Go to Slow flow</ng-container>
          <ng-container *ngIf="!isQuickFlow">Go to Control</ng-container>
        </h1>
        <hr class="has-background-dark" />
        <p class="is-size-6" *ngIf="isQuickFlow">
          We are unable to detect anything. Please send the device to slow flow
          scan.
        </p>
        <p class="is-size-6" *ngIf="!isQuickFlow">
          We are unable to detect anything. Please send the device to control
          workflow.
        </p>
        <hr class="has-background-dark" />
      </div>
    </div>

    <div class="card-content" *ngIf="item.screenType !== ScreenType.Unknown">
      <div class="content mt-10 mt-0-small-mobile">
        <div class="button is-circle is-dark m-auto">
          <i
            class="icon icon-tool"
            [ngClass]="{
              'icon-x-circle': item.screenType === ScreenType.CCFL,
              'icon-tool': item.screenType === ScreenType.Unknown,
              'icon-check3':
                item.screenType !== ScreenType.CCFL &&
                item.screenType !== ScreenType.Unknown
            }"
          ></i>
        </div>
        <h1
          class="title is-1 has-text-weight-bold has-text-centered mt-4 mb-10 mb-0-small-mobile"
        >
          {{ item.screenType === ScreenType.CCFL ? 'LCD' : 'LED' }}
        </h1>
        <table>
          <tbody>
            <tr>
              <td>
                <b>Brand</b>
              </td>
              <td>{{ item.model.brand.name }}</td>
            </tr>
            <tr>
              <td>
                <b>Model</b>
              </td>
              <td>{{ item.model.name }}</td>
            </tr>
            <tr>
              <td>
                <b>Backlight detection</b>
              </td>
              <td>{{ item.screenType }}</td>
            </tr>
            <tr>
              <td>
                <b>Datetime</b>
              </td>
              <td>{{ item.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="has-position-fixed">
      <div
        class="is-flex is-flex-direction-column is-align-item-center is-justify-content-center"
      >
        <button
          class="button is-black is-rounded has-icon"
          routerLink="/scans/create"
        >
          <span class="icon is-small">
            <i class="icon icon-camera"></i>
          </span>
          <span>New Scan</span>
        </button>
        <button class="button is-ghost has-text-dark mt-2" routerLink="/scans">
          <span class="has-text-weight-normal">Go to scan list</span>
        </button>
      </div>
    </div>
  </div>
</div>
