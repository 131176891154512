<div
  class="is-flex is-align-items-center is-white-space-nowrap"
  [ngClass]="{ 'tooltip has-tooltip-left': tooltipText }"
  [attr.data-tooltip]="tooltipText"
>
  <span
    class="bullet progress-{{ progressNumber }}"
    [ngClass]="{ 'is-white': progressValue < progressNumber }"
    *ngFor="let progressNumber of totalValueArray"
  ></span>
</div>
