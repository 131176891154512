import { Component, OnInit } from '@angular/core'
import { BreadcrumbService } from '../../services/breadcrumb.service'
import { User } from '../../interfaces/resources/user.interface'
import { AuthService } from '../../services/auth.service'

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {
  currentUser: User
  constructor(
    breadcrumbService: BreadcrumbService,
    private authService: AuthService
  ) {
    breadcrumbService.breadcrumbLinks.next([
      {
        label: 'Page not found'
      }
    ])
  }

  async ngOnInit(): Promise<void> {
    await this.authService.currentUser.subscribe((userRes: User) => {
      this.currentUser = userRes
    })
  }
}
