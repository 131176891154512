import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { ScanType } from '../../../../../../shared/enums/scan-type.enum'
import { ScreenType } from '../../../../../../shared/enums/screen-type.enum'
import { CaseListComponent } from '../../../abacus/components/case-list.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { YieldType } from '../../../abacus/enums/yield-type.enum'
import { Filter } from '../../../abacus/interfaces/filter.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../../abacus/interfaces/yield.interface'
import { AuthService } from '../../../abacus/services/auth.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FilterService } from '../../../abacus/services/filter.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseListTemplate } from '../../../abacus/templates/case-list.template'
import { brandDefinition } from '../../brand/brand.definition'
import { scanDefinition } from '../scan.definition'

@Component({
  selector: 'app-scan-list',
  template: caseListTemplate
})
export class ScanListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = scanDefinition
  yields: Yield[] = [
    {
      label: 'Ref',
      className: 'min-width-cell',
      property: 'reference',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'image',
      property: 'image',
      className: 'is-hidden-touch',
      type: YieldType.Download,
      disableOrderBy: true
    },
    {
      label: 'date',
      property: 'createdAt',
      type: YieldType.Datetime
    },
    {
      label: 'type',
      property: 'type',
      type: YieldType.Text,
      className: 'is-hidden-touch'
    },
    {
      label: 'Brand',
      property: 'model.brand.name',
      secondProperty: 'model.name',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'detection',
      property: 'screenType',
      type: YieldType.Text
    },
    {
      label: 'User',
      property: 'user.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'site',
      property: 'user.site.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    }
  ]

  filters: Filter[] = [
    {
      label: 'Date',
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      className: 'is-6',
      inputType: InputType.DateRange
    },
    {
      label: 'Site',
      className: 'is-3 is-hidden-mobile',
      property: 'siteIds',
      placeholder: 'All sites',
      inputType: InputType.Select,
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('sites')
    },
    {
      label: 'Scan type',
      className: 'is-3 is-hidden-mobile',
      property: 'type',
      placeholder: 'All scan types',
      inputType: InputType.Select,
      selectOptions: Object.keys(ScanType).map((key) => ({
        label: key,
        value: ScanType[key as keyof typeof ScanType]
      }))
    },
    {
      label: 'Brand',
      className: 'is-4',
      inputType: InputType.MultiSearch,
      placeholder: 'Search a brand...',
      searchResources: [brandDefinition],
      properties: {
        brandIds: 'brandIds'
      }
    },
    {
      label: 'Detection',
      className: 'is-4',
      property: 'screenType',
      placeholder: 'All screen types',
      inputType: InputType.Select,
      selectOptions: Object.keys(ScreenType).map((key) => ({
        label: key,
        value: ScreenType[key as keyof typeof ScreenType]
      }))
    },
    {
      label: 'User',
      className: 'is-4 is-hidden-mobile',
      property: 'userId',
      inputType: InputType.Select,
      placeholder: 'All users',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users')
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService,
    private componentResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
