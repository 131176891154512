import { Component, Input } from '@angular/core'

import { environment } from '../../../../../environments/environment'

@Component({
  selector: 'case-download-yield',
  templateUrl: './download-yield.component.html',
  styleUrls: ['./download-yield.component.scss']
})
export class DownloadYieldComponent {
  @Input() filePath: string
  storagePath: string = environment.storagePath
}
