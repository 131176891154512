import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const modelDefinition: ResourceDefinition = {
  title: 'Models',
  nameSingular: 'model',
  namePlural: 'models',
  className: 'Model',
  mainIdentifier: 'name',
  slug: 'models',
  path: 'models',
  icon: 'icon-circle',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editModels',
      action: (model) => ({
        type: ActionType.Link,
        link: {
          path: `${modelDefinition.path}/${model.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteModels',
      action: (model) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: model,
          definition: modelDefinition
        }
      }),
      disabled: (model) =>
        model.scansCount > 0 ||
        model.evaluationsCount > 0 ||
        model.controlsCount > 0
    }
  ]
}
