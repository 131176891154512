import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { CameraGuard } from '../../guards/camera.guard'
import { RedirectToControlGuard } from '../../guards/redirect-to-control.guard'
import { ScanCreateEditComponent } from './scan-create-edit/scan-create-edit.component'
import { ScanDetailComponent } from './scan-detail/scan-detail.component'
import { ScanListComponent } from './scan-list/scan-list.component'

export const scanRoutes: Route[] = [
  {
    path: 'scans',
    component: ScanListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseScans', 'browseOwnScans']
    }
  },
  {
    path: 'scans/create',
    component: ScanCreateEditComponent,
    canActivate: [
      AuthGuard,
      PermissionGuard,
      RedirectToControlGuard,
      CameraGuard
    ],
    data: {
      mode: ResourceMode.Create,
      permission: 'addScans'
    }
  },
  {
    path: 'scans/:id',
    component: ScanDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readScans', 'readOwnScans']
    }
  }
]
