<div class="container container-result" *ngIf="item">
  <div class="card is-radiusless is-fullheight">
    <div class="card-content">
      <div class="content">
        <p
          class="title my-10 is-size-5 has-text-centered mt-0-small-mobile mb-0-small-mobile"
        >
          Summary of your entry
        </p>
        <table class="table">
          <tr>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td><b>Reference</b></td>
            <td>{{ item.reference }}</td>
          </tr>
          <tr>
            <td><b>Brand</b></td>
            <td>{{ item.model.brand.name }}</td>
          </tr>
          <tr>
            <td><b>Panel Backlight</b></td>
            <td>{{ item.manualScreenType }}</td>
          </tr>
          <tr>
            <td><b>Datetime</b></td>
            <td>{{ item.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="has-position-fixed">
      <div
        class="is-flex is-flex-direction-column is-align-item-center is-justify-content-center"
      >
        <button
          class="button is-black is-rounded has-icon"
          routerLink="/controls/create"
        >
          <span class="icon is-small">
            <i class="icon icon-camera"></i>
          </span>
          <span> New control </span>
        </button>
        <button class="button is-ghost mt-2" routerLink="/controls">
          <span class="has-text-dark has-text-weight-normal">
            Go to control list
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
