<div class="container container-result">
  <div class="card is-radiusless is-fullheight has-background-dark">
    <div class="card-content">
      <div class="content mt-10 mt-0-small-mobile">
        <div class="button is-circle is-dark is-large m-auto">
          <i class="fa-solid fa-wrench is-size-2"></i>
        </div>
        <h1
          class="title is-1 has-text-weight-bold has-text-centered mt-4 mb-10 mb-0-small-mobile has-text-white"
        >
          Go to Control
        </h1>
        <hr class="has-background-white" />
        <p class="is-size-6 has-text-white has-text-centered">
          This device has been selected for manual control
        </p>
        <hr class="has-background-white" />
      </div>
    </div>
    <div class="has-position-fixed">
      <div
        class="is-flex is-flex-direction-column is-align-item-center is-justify-content-center"
      >
        <button
          class="button is-white has-text-dark mt-2 is-fullwidth"
          routerLink="/scans"
        >
          <span class="has-text-weight-normal">Go to scan list</span>
        </button>
      </div>
    </div>
  </div>
</div>
