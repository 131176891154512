<div
  class="is-flex is-justify-content-space-between is-align-items-center mb-5"
>
  <div class="left-part">
    <h1
      class="title is-2 has-text-weight-light"
      *ngIf="mode === ResourceMode.Create"
    >
      Add a {{ definition.nameSingular }}
    </h1>
    <h1
      class="title is-2 has-text-weight-light"
      *ngIf="mode === ResourceMode.Edit"
    >
      Update a {{ definition.nameSingular }}
    </h1>
  </div>
  <div class="right-part">
    <button
      class="button is-dark is-rounded is-hidden-touch"
      (click)="submit()"
      [ngClass]="{ 'is-loading': loading }"
    >
      Save
    </button>
    <button
      class="button is-dark is-rounded is-hidden-desktop"
      (click)="submit()"
      [ngClass]="{ 'is-loading': loading }"
    >
      <i class="icon icon-save"></i>
    </button>
  </div>
</div>

<section>
  <form [formGroup]="form" *ngIf="form">
    <div class="card p-4">
      <div class="columns">
        <div class="column">
          <article class="message is-warning">
            <div class="message-body">
              ⚠️ Changes the name or identifier of a role will affect all users
              who have this role. They will need to re-login to the system.
            </div>
          </article>
        </div>
      </div>

      <!-- Fields -->
      <div class="columns is-multiline is-align-items-flex-end">
        <ng-container *ngFor="let field of resolvedFields">
          <ng-container *caseHasPermission="field.permission">
            <div
              class="column is-flex is-align-items-flex-end  {{
                field.className || 'is-6'
              }}"
              [id]="field.id"
              *ngIf="!field.hidden"
            >
              <case-input
                [type]="field.inputType"
                [label]="field.label"
                [placeholder]="field.placeholder"
                [secondPlaceholder]="field.secondPlaceholder"
                [initialValue]="field.initialValue"
                [searchResources]="field.searchResources"
                [resourceName]="definition.slug"
                [searchParams]="field.searchParams"
                [maxSelectedItems]="field.maxSelectedItems"
                [selectOptions]="field.selectOptions"
                [min]="field.min"
                [max]="field.max"
                [copyDateFromOnDateTo]="field.copyDateFromOnDateTo"
                [readonly]="field.readonly"
                [validators]="field.validators"
                [helpText]="field.helpText"
                [showErrors]="showErrors"
                (valueChanged)="onValueChanged($event, field)"
              ></case-input>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <!-- Permissions -->
      <div class="columns">
        <div class="column my-6">
          <h2 class="title is-5">Permissions</h2>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <!-- Mass selection -->
          <a
            class="small-meta mass-selection-button has-text-grey-dark"
            (click)="selectAll()"
            >Select all</a
          >
          <a
            class="small-meta mass-selection-button has-text-grey-dark"
            (click)="selectNone()"
            >Select none</a
          >
        </div>
      </div>

      <div class="columns is-multiline is-align-items-flex-end">
        <div
          class="column is-4-tablet is-3-desktop py-0"
          *ngFor="let permission of permissions"
        >
          <div class="control my-4">
            <label
              class="checkbox"
              [ngClass]="{
                'is-checked': permission.selected
              }"
              (click)="toggleSelected(permission)"
              >{{ permission.name }}
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
