import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { controlDefinition } from '../control.definition'
import { ScreenType } from '../../../../../../shared/enums/screen-type.enum'
import { CaseDetailComponent } from '../../../abacus/components/case-detail.component'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'

@Component({
  templateUrl: './control-detail.component.html',
  styleUrls: ['./control-detail.component.scss']
})
export class ControlDetailComponent
  extends CaseDetailComponent
  implements OnInit
{
  ScreenType = ScreenType
  definition: ResourceDefinition = controlDefinition

  constructor(
    breadcrumbService: BreadcrumbService,
    resourceService: ResourceService,
    flashMessageService: FlashMessageService,
    activatedRoute: ActivatedRoute
  ) {
    super(
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit(): Promise<void> {
    await this.initDetailView()
  }
}
