import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const errorDefinition: ResourceDefinition = {
  title: 'Errors',
  nameSingular: 'error',
  namePlural: 'errors',
  className: 'Error',
  mainIdentifier: 'uuid',
  slug: 'errors',
  path: 'errors',
  icon: 'icon-x-circle',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: null,
  childrenThatPreventDelete: [],
  dropdownLinks: []
}
