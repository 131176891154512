import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Reusability } from '../../../../../../shared/enums/reusability.enum'
import { CaseListComponent } from '../../../abacus/components/case-list.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { YieldType } from '../../../abacus/enums/yield-type.enum'
import { Filter } from '../../../abacus/interfaces/filter.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../../abacus/interfaces/yield.interface'
import { AuthService } from '../../../abacus/services/auth.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FilterService } from '../../../abacus/services/filter.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseListTemplate } from '../../../abacus/templates/case-list.template'
import { brandDefinition } from '../../brand/brand.definition'
import { evaluationDefinition } from '../evaluation.definition'

@Component({ template: caseListTemplate })
export class EvaluationListComponent
  extends CaseListComponent
  implements OnInit
{
  definition: ResourceDefinition = evaluationDefinition
  yields: Yield[] = [
    {
      label: 'Ref',
      className: 'min-width-cell',
      property: 'reference',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'image',
      property: 'image',
      type: YieldType.Download,
      className: 'is-hidden-touch',
      disableOrderBy: true
    },
    {
      label: 'date',
      property: 'createdAt',
      type: YieldType.Datetime
    },
    {
      label: 'result',
      property: 'reusability',
      type: YieldType.Text
    },

    {
      label: 'Brand',
      property: 'model.brand.name',
      secondProperty: 'model.name',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'age (y)',
      property: 'age',
      className: 'is-hidden-touch',
      type: YieldType.Text
    },
    {
      label: 'User',
      property: 'user.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'site',
      property: 'user.site.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    }
  ]

  filters: Filter[] = [
    {
      label: 'Date',
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      className: 'is-6',
      inputType: InputType.DateRange
    },
    {
      label: 'Brand',
      className: 'is-3',
      inputType: InputType.MultiSearch,
      searchResources: [brandDefinition],
      properties: {
        brandIds: 'brandIds'
      }
    },
    {
      label: 'Site',
      className: 'is-3 is-hidden-mobile',
      inputType: InputType.Select,
      property: 'siteIds',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('sites')
    },
    {
      label: 'Evaluation Result',
      className: 'is-6',
      inputType: InputType.Select,
      property: 'reusability',
      selectOptions: [
        { label: 'Reusable', value: Reusability.Reusable },
        { label: 'Not reusable', value: Reusability.NotReusable },
        { label: 'Unknown', value: Reusability.Unknown }
      ]
    },
    {
      label: 'Age From',
      className: 'is-3',
      inputType: InputType.Number,
      property: 'ageFrom'
    },
    {
      label: 'Age To',
      className: 'is-3',
      inputType: InputType.Number,
      property: 'ageTo'
    },
    {
      label: 'User',
      className: 'is-6 is-hidden-mobile',
      inputType: InputType.Select,
      property: 'userId',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users')
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService,
    private componentResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
