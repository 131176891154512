import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const scanDefinition: ResourceDefinition = {
  title: 'Scans',
  nameSingular: 'scan',
  namePlural: 'scans',
  className: 'Scan',
  mainIdentifier: 'reference',
  slug: 'scans',
  path: 'scans',
  icon: 'icon-aperture',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: null,
  childrenThatPreventDelete: [],
  dropdownLinks: []
}
