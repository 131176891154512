import { MenuItem } from './abacus/interfaces/menu-item.interface'

export const menuItems: MenuItem[] = [
  {
    label: 'Scans',
    permissionsOr: ['browseScans', 'browseOwnScans'],
    routePath: '/scans',
    icon: 'icon-aperture',
    items: []
  },
  {
    label: 'Controls',
    permissionsOr: ['browseControls', 'browseOwnControls'],
    routePath: '/controls',
    icon: 'icon-file-text',
    items: []
  },
  {
    label: 'Re use Evaluations',
    permissionsOr: ['browseEvaluations', 'browseOwnEvaluations'],
    routePath: '/evaluations',
    icon: 'icon-activity',
    items: []
  },
  {
    label: 'Settings',
    permissionsOr: [
      'browseUsers',
      'browseRoles',
      'browseSites',
      'browseBrands',
      'browseModels',
      'browseErrors'
    ],
    icon: 'icon-settings',
    items: [
      {
        label: 'Users',
        permissionsOr: ['browseUsers'],
        routePath: '/users'
      },
      {
        label: 'Sites',
        permissionsOr: ['browseSites'],
        routePath: '/sites'
      },
      {
        label: 'Models',
        permissionsOr: ['browseModels'],
        routePath: '/models'
      },
      {
        label: 'Brands',
        permissionsOr: ['browseBrands'],
        routePath: '/brands'
      },
      {
        label: 'Errors',
        permissionsOr: ['browseErrors'],
        routePath: '/errors'
      }
    ]
  }
]
