import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { ModelCreateEditComponent } from './model-create-edit/model-create-edit.component'
import { ModelListComponent } from './model-list/model-list.component'

export const modelRoutes: Route[] = [
  {
    path: 'models',
    component: ModelListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseModels'
    }
  },
  {
    path: 'models/create',
    component: ModelCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addModels'
    }
  },
  {
    path: 'models/:id/edit',
    component: ModelCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editModels'
    }
  }
]
