import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { EventService } from '../../../abacus/services/event.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'
import { evaluationDefinition } from '../evaluation.definition'

@Component({
  selector: 'app-evaluation-create-edit',
  template: caseCreateEditTemplate
})
export class EvaluationCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  showLoaderInsteadOfForm = true

  definition: ResourceDefinition = evaluationDefinition
  fields: Field[] = [
    {
      id: 'image',
      label: 'Image',
      property: 'image',
      required: true,
      inputType: InputType.Image
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private eventService: EventService,
    private componentRouter: Router
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    // If we come from detail (back button clicked), we need to redirect to list.
    if (
      this.eventService.previousUrl.includes('/evaluations/') ||
      this.eventService.previousUrl.includes('/error-api')
    ) {
      return this.componentRouter.navigate(['/evaluations'])
    }

    await this.initCreateEditView()

    this.submit()
  }
}
