import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuard } from './abacus/guards/auth.guard'
import { caseRoutes } from './abacus/routes/case.routes'
import { CameraComponent } from './pages/camera/camera.component'
import { ErrorApiComponent } from './pages/error-api/error-api.component'
import { HomeComponent } from './pages/home/home.component'
import { brandRoutes } from './resources/brand/brand.routes'
import { controlRoutes } from './resources/control/control.routes'
import { errorRoutes } from './resources/error/error.routes'
import { evaluationRoutes } from './resources/evaluation/evaluation.routes'
import { modelRoutes } from './resources/model/model.routes'
import { scanRoutes } from './resources/scan/scan.routes'
import { siteRoutes } from './resources/site/site.routes'
import { userRoutes } from './resources/user/user.routes'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'camera',
    component: CameraComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'error-api',
    component: ErrorApiComponent,
    canActivate: [AuthGuard]
  },
  ...userRoutes,
  ...scanRoutes,
  ...errorRoutes,
  ...brandRoutes,
  ...siteRoutes,
  ...controlRoutes,
  ...evaluationRoutes,
  ...modelRoutes,
  ...(caseRoutes as Routes)
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
