<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <div class="columns">
        <div class="column col-login">
          <div>
            <figure class="logo" style="padding: 0">
              <img src="./assets/images/logo.svg" alt="logo" />
            </figure>
            <h3 class="title is-2 mt-5 mb-2">Reset your password</h3>
            <p class="mb-5">
              Check your email for a link to reset your password. If it doesn’t
              appear within a few minutes, check your spam folder.
            </p>
            <form [formGroup]="form" (submit)="submit()">
              <div class="field mb-5">
                <div class="control">
                  <input
                    type="email"
                    formControlName="email"
                    class="input is-large has-background-light"
                    placeholder="Email*"
                  />
                </div>
              </div>

              <button
                type="submit"
                class="button is-link is-large is-fullwidth mb-3"
              >
                Send password reset email
              </button>
              <a class="has-text-dark is-underlined" routerLink="/login"
                >Sign in instead</a
              >
            </form>
          </div>
        </div>
        <div class="column col-decoration">
          <div class="drawing">
            <img src="../../../../assets/images/bg-3.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
