<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <div class="columns">
        <div class="column col-login">
          <div>
            <figure class="avatar">
              <img src="./assets/images/logo.svg" alt="logo" />
            </figure>
            <h3 class="title is-2 mt-5 mb-2">
              Reset your<br />
              password
            </h3>
            <form [formGroup]="form" (submit)="submit()">
              <div class="field mb-5">
                <div class="control">
                  <input
                    class="input is-large"
                    type="password"
                    placeholder="New password"
                    formControlName="password"
                  />
                </div>
              </div>

              <button
                type="submit"
                class="button is-block is-link is-large is-fullwidth mb-3"
              >
                Reset password
              </button>
              <a routerLink="/login">Sign in</a>
            </form>
          </div>
        </div>
        <div class="column col-decoration">
          <div class="drawing">
            <img src="../../../../assets/images/bg-3.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
