import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { caseCreateEditTemplate } from 'src/app/abacus/templates/case-create-edit.template'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { AuthService } from '../../../abacus/services/auth.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { EventService } from '../../../abacus/services/event.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { brandDefinition } from '../../brand/brand.definition'
import { scanDefinition } from '../scan.definition'

@Component({
  selector: 'app-scan-create-edit',
  template: caseCreateEditTemplate
})
export class ScanCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = scanDefinition
  fields: Field[] = [
    {
      id: 'image',
      label: 'Image',
      property: 'image',
      required: true,
      inputType: InputType.Image
    },
    {
      id: 'brand',
      label: 'Select brand',
      placeholder: 'Select a brand...',
      className: 'is-12 is-hidden-if-selected',
      property: 'brandId',
      required: true,
      inputType: InputType.MultiSearch,
      maxSelectedItems: 1,
      searchResources: [brandDefinition],
      properties: {
        brandId: 'brandIds'
      }
    }
  ]
  showLoaderInsteadOfForm = false

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private authService: AuthService,
    private eventService: EventService,
    private componentRouter: Router
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  async ngOnInit() {
    // If we come from detail (back button clicked), we redirect to list.
    if (
      this.eventService.previousUrl.includes('/scans/') ||
      this.eventService.previousUrl.includes('/error-api')
    ) {
      return this.componentRouter.navigate(['/scans'])
    }

    await this.initCreateEditView()

    const isQuickFlow: boolean = !(await this.authService.can(
      'canDoSlowFlowScans'
    ))

    if (isQuickFlow) {
      this.showLoaderInsteadOfForm = true
      this.form.get('brandId').disable()
      this.submit()
    }
  }
}
