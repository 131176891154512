import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { ErrorListComponent } from './error-list/error-list.component'

export const errorRoutes: Route[] = [
  {
    path: 'errors',
    component: ErrorListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseErrors'
    }
  }
]
