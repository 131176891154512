import { Route } from '@angular/router'

import { BrandCreateEditComponent } from './brand-create-edit/brand-create-edit.component'
import { BrandListComponent } from './brand-list/brand-list.component'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { ResourceMode } from '../../abacus/enums/resource-mode.enum'

export const brandRoutes: Route[] = [
  {
    path: 'brands',
    component: BrandListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseBrands'
    }
  },
  {
    path: 'brands/create',
    component: BrandCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addBrands'
    }
  },
  {
    path: 'brands/:id/edit',
    component: BrandCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editBrands'
    }
  }
]
