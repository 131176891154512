import { Route } from '@angular/router';

import { ResourceMode } from '../../abacus/enums/resource-mode.enum';
import { AuthGuard } from '../../abacus/guards/auth.guard';
import { PermissionGuard } from '../../abacus/guards/permission.guard';
import { CameraGuard } from '../../guards/camera.guard';
import { EvaluationCreateEditComponent } from './evaluation-create-edit/evaluation-create-edit.component';
import { EvaluationDetailComponent } from './evaluation-detail/evaluation-detail.component';
import { EvaluationListComponent } from './evaluation-list/evaluation-list.component';

export const evaluationRoutes: Route[] = [
  {
    path: 'evaluations',
    component: EvaluationListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['browseEvaluations', 'browseOwnEvaluations']
    }
  },
  {
    path: 'evaluations/create',
    component: EvaluationCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard, CameraGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addEvaluations'
    }
  },
  {
    path: 'evaluations/:id',
    component: EvaluationDetailComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permissions: ['readEvaluations', 'readOwnEvaluations']
    }
  }
]
