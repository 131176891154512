import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { FieldSpecialRule } from '../abacus/interfaces/field-special-rule.interface'

// This guard redirects to the camera page if the image query param is not present.
@Injectable({
  providedIn: 'root'
})
export class CameraGuard {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const specialRules: string = route.queryParams.specialRules

    if (specialRules) {
      const parsedSpecialRules: FieldSpecialRule[] = JSON.parse(specialRules)

      if (parsedSpecialRules.some((rule) => rule.fieldId === 'image')) {
        return true
      }
    }

    this.router.navigate(['/camera'], {
      queryParams: { redirectTo: '/' + route.url.join('/') }
    })
    return false
  }
}
