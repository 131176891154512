import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { ScreenType } from '../../../../../../shared/enums/screen-type.enum'
import { CaseListComponent } from '../../../abacus/components/case-list.component'
import { YieldType } from '../../../abacus/enums/yield-type.enum'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../../abacus/interfaces/yield.interface'
import { caseListTemplate } from '../../../abacus/templates/case-list.template'
import { brandDefinition } from '../../brand/brand.definition'
import { controlDefinition } from '../control.definition'

import { InputType } from '../../../abacus/enums/input-type.enum'
import { Filter } from '../../../abacus/interfaces/filter.interface'
import { AuthService } from '../../../abacus/services/auth.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FilterService } from '../../../abacus/services/filter.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'

@Component({
  selector: 'app-control-list',
  template: caseListTemplate
})
export class ControlListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = controlDefinition
  yields: Yield[] = [
    {
      label: 'Ref',
      property: 'reference',
      className: 'min-width-cell',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'Image',
      property: 'image',
      type: YieldType.Download,
      className: 'is-hidden-touch',
      disableOrderBy: true
    },
    { label: 'date', property: 'createdAt', type: YieldType.Datetime },
    {
      label: 'Brand',
      property: 'model.brand.name',
      secondProperty: 'model.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'Detection',
      property: 'screenType',
      type: YieldType.Text,
      className: 'is-hidden-touch'
    },
    {
      label: 'manual check',
      property: 'manualScreenType',
      type: YieldType.Text
    },
    {
      label: 'User',
      property: 'user.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    },
    {
      label: 'Site',
      property: 'user.site.name',
      className: 'is-hidden-touch',
      type: YieldType.Text,
      disableOrderBy: true
    }
  ]

  filters: Filter[] = [
    {
      label: 'Date',
      properties: {
        dateFrom: 'dateFrom',
        dateTo: 'dateTo'
      },
      className: 'is-6',
      inputType: InputType.DateRange
    },
    {
      label: 'Brand',
      className: 'is-3',
      inputType: InputType.MultiSearch,
      searchResources: [brandDefinition],
      properties: {
        brandIds: 'brandIds'
      }
    },
    {
      label: 'Site',
      className: 'is-3 is-hidden-mobile',
      inputType: InputType.Select,
      property: 'siteIds',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('sites')
    },
    {
      label: 'Type of screen (manually detected)',
      className: 'is-4',
      property: 'manualScreenType',
      inputType: InputType.Select,
      selectOptions: Object.keys(ScreenType).map((key: ScreenType) => ({
        label: key,
        value: ScreenType[key]
      }))
    },
    {
      label: 'Correlates with the API response',
      className: 'is-4 is-hidden-touch',
      property: 'correlation',
      inputType: InputType.Select,
      selectOptions: [
        {
          label: 'Correlated',
          value: 'correlated'
        },
        {
          label: 'Uncorrelated',
          value: 'uncorrelated'
        }
      ]
    },
    {
      label: 'User',
      className: 'is-4 is-hidden-mobile',
      inputType: InputType.Select,
      property: 'userId',
      selectOptions: () =>
        this.componentResourceService.listSelectOptions('users')
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService,
    private readonly componentResourceService: ResourceService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
