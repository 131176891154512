<nav class="navbar" role="navigation" aria-label="main navigation" #menu>
  <div
    class="navbar-wrapper is-flex is-align-items-center is-justify-content-space-between is-flex-direction-row-reverse is-fullheight"
  >
    <div class="header-right pr-6">
      <div
        class="dropdown is-right has-text-right"
        [ngClass]="{ 'is-active': showUserMenu }"
        *ngIf="currentUser"
      >
        <div
          class="dropdown-trigger user-box"
          (click)="showUserMenu = !showUserMenu"
        >
          <span
            >{{ currentUser.firstName | slice : 0 : 1 }}.
            {{ currentUser.lastName }}</span
          >
          <span class="icon is-small ml-1">
            <i class="icon icon-chevron-down is-size-5" aria-hidden="true"></i>
          </span>
        </div>
        <div *ngIf="showUserMenu" class="dropdown-menu">
          <div class="dropdown-content">
            <div class="dropdown-item">
              <span
                >{{ currentUser.firstName }} {{ currentUser.lastName }}</span
              >
            </div>

            <a class="dropdown-item" routerLink="/logout">Logout</a>
          </div>
        </div>
      </div>
    </div>
    <a
      routerLink="/"
      class="logo is-flex is-align-items-center is-justify-content-center px-4 pt-0"
      [ngClass]="{ 'ml-empty': !showSideMenuButton }"
    >
      <img src="/assets/images/logo.svg" width="103" height="20" />
    </a>

    <a
      role="button"
      class="navbar-burger burger ml-0 is-flex is-align-items-center is-justify-content-flex-start pl-6"
      aria-label="menu"
      aria-expanded="false"
      data-target="navbarBasicExample"
      (click)="toggleMenu()"
      *ngIf="showSideMenuButton"
    >
      <i class="icon icon-menu is-size-2"></i>
    </a>
  </div>
  <div class="hiding-layer" #hidingLayer></div>
  <div class="navbar-menu" id="create-dropdown" #menuItemsEl>
    <div class="navbar-end">
      <ul class="menu-list mt-5 menu-list--top">
        <ng-container *ngFor="let menuItem of menuItems; let i = index">
          <li *caseHasPermission="{ requireOr: menuItem.permissionsOr }">
            <!-- Direct link -->
            <ng-container *ngIf="menuItem.routePath">
              <a [routerLink]="menuItem.routePath" routerLinkActive="is-active">
                <i class="icon mr-3 is-size-4" [ngClass]="menuItem.icon"></i>
                <span class="pl-1">{{ menuItem.label }}</span>
              </a>
            </ng-container>

            <!-- Sub list -->
            <ng-container *ngIf="menuItem.items && menuItem.items.length">
              <a
                (click)="toggleAccordion('accordion-' + i)"
                class="has-submenu"
              >
                <span>
                  <i class="icon mr-3 is-size-4" [ngClass]="menuItem.icon"></i>
                  <span class="pl-1">{{ menuItem.label }}</span>
                </span>
                <i
                  class="icon icon-chevron-down mr-1"
                  *ngIf="activeAccordion !== 'accordion-' + i"
                ></i>
                <i
                  class="icon icon-chevron-up mr-1"
                  *ngIf="activeAccordion === 'accordion-' + i"
                ></i>
              </a>

              <ul *ngIf="activeAccordion === 'accordion-' + i">
                <ng-container *ngFor="let subItem of menuItem.items">
                  <li *caseHasPermission="{ requireOr: subItem.permissionsOr }">
                    <a
                      [routerLink]="subItem.routePath"
                      routerLinkActive="is-active"
                    >
                      <span class="pl-1">{{ subItem.label }}</span>
                    </a>
                  </li>
                </ng-container>
              </ul>
            </ng-container>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
