import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { CaseListComponent } from '../../../abacus/components/case-list.component'
import { YieldType } from '../../../abacus/enums/yield-type.enum'
import { Filter } from '../../../abacus/interfaces/filter.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../../abacus/interfaces/yield.interface'
import { AuthService } from '../../../abacus/services/auth.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FilterService } from '../../../abacus/services/filter.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseListTemplate } from '../../../abacus/templates/case-list.template'
import { siteDefinition } from '../site.definition'

@Component({
  selector: 'app-site-list',
  styleUrls: ['./site-list.component.scss'],
  template: caseListTemplate
})
export class SiteListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = siteDefinition
  yields: Yield[] = [
    {
      label: 'name',
      property: 'name',
      type: YieldType.Text
    },
    {
      label: 'Control rate (in %)',
      property: 'controlRate',
      type: YieldType.Text
    },
    {
      label: 'age limit',
      property: 'maxAge',
      type: YieldType.Text
    },
    {
      label: 'users',
      property: 'usersCount',
      disableOrderBy: true
    }
  ]

  filters: Filter[] = []

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
