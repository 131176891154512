import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const evaluationDefinition: ResourceDefinition = {
  title: 'Re use evaluations',
  nameSingular: 're use evaluation',
  namePlural: 're use evaluations',
  className: 'Evaluation',
  mainIdentifier: 'reference',
  slug: 'evaluations',
  path: 'evaluations',
  icon: 'icon-activity',
  hasDetailPage: true,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: null,
  childrenThatPreventDelete: [],
  dropdownLinks: []
}
