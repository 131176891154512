import { Yield } from '../../interfaces/yield.interface'

export const roleYields: Yield[] = [
  {
    label: 'Name',
    property: 'displayName'
  },
  {
    label: 'Identifier',
    property: 'name'
  }
]
