<div class="container container-result" *ngIf="item">
  <div
    class="card is-radiusless is-fullheight"
    [ngClass]="{
      'has-background-danger': item.reusability === Reusability.NotReusable,
      'has-background-warning': item.reusability === Reusability.Unknown,
      'has-background-success': item.reusability === Reusability.Reusable
    }"
  >
    <div class="card-content">
      <div class="content mt-10 mt-0-small-mobile">
        <div class="button is-circle is-dark m-auto">
          <i
            class="fa-solid"
            [ngClass]="{
              'fa-recycle': item.reusability === Reusability.NotReusable,
              'fa-ban': item.reusability === Reusability.Unknown,
              'fa-bolt': item.reusability === Reusability.Reusable
            }"
          ></i>
        </div>
        <ng-container *ngIf="item.reusability === Reusability.NotReusable">
          <h1
            class="title is-1 has-text-weight-bold has-text-centered mt-4 mb-10 mb-0-small-mobile"
          >
            To be recycled
          </h1></ng-container
        >
        <ng-container *ngIf="item.reusability === Reusability.Reusable">
          <h1
            class="title is-1 has-text-weight-bold has-text-centered mt-4 mb-10 mb-0-small-mobile"
          >
            Could be reused
          </h1></ng-container
        >

        <ng-container *ngIf="item.reusability === Reusability.Unknown">
          <h1
            class="title is-1 has-text-weight-bold has-text-centered mt-4 mb-10 mb-0-small-mobile"
          >
            Unknown
          </h1>
        </ng-container>

        <table>
          <tbody>
            <tr *ngIf="item.referenceId">
              <td>
                <b>Reference:</b>
              </td>
              <td>{{ item.referenceId }}</td>
            </tr>
            <tr *ngIf="item.model.brand">
              <td>
                <b>Brand:</b>
              </td>
              <td>{{ item.model.brand.name }}</td>
            </tr>
            <tr *ngIf="item.model.name">
              <td>
                <b>Model:</b>
              </td>
              <td>{{ item.model.name }}</td>
            </tr>
            <tr>
              <td>
                <b>Age:</b>
              </td>
              <td *ngIf="!item.age">Unknown</td>
              <td *ngIf="item.age">
                {{ item.age }} year<span *ngIf="item.age > 1">s</span>
              </td>
            </tr>
            <tr>
              <td>
                <b>Datetime:</b>
              </td>
              <td>{{ item.createdAt | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="has-position-fixed">
      <div
        class="is-flex is-flex-direction-column is-align-item-center is-justify-content-center"
      >
        <button
          class="button is-black is-rounded has-icon"
          routerLink="/evaluations/create"
        >
          <span class="icon is-small">
            <i class="icon icon-camera"></i>
          </span>
          <span> New re use evaluations </span>
        </button>
        <button
          class="button is-ghost has-text-dark mt-2"
          routerLink="/evaluations"
        >
          Go to re use evaluations
        </button>
      </div>
    </div>
  </div>
</div>
