import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { ScreenType } from '../../../../../../shared/enums/screen-type.enum'
import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { EventService } from '../../../abacus/services/event.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'
import { brandDefinition } from '../../brand/brand.definition'
import { controlDefinition } from '../control.definition'

@Component({
  selector: 'app-control-create-edit',
  template: caseCreateEditTemplate
})
export class ControlCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = controlDefinition
  showLoaderInsteadOfForm = false
  fields: Field[] = [
    {
      id: 'image',
      label: 'image',
      property: 'image',
      required: true,
      inputType: InputType.Image
    },
    {
      label: 'Panel backlight',
      property: 'manualScreenType',
      required: true,
      inputType: InputType.Radio,
      className: 'is-panel-backlight',
      selectOptions: Object.keys(ScreenType)
        .filter((key) => key !== ScreenType.Unknown)
        .map((key) => ({
          label: key,
          value: ScreenType[key]
        }))
    },
    {
      label: 'Select brand',
      placeholder: 'Select a brand...',
      className: 'is-12 is-hidden-if-selected',
      property: 'brandId',
      inputType: InputType.MultiSearch,
      maxSelectedItems: 1,
      required: true,
      searchResources: [brandDefinition],
      properties: {
        brandId: 'brandIds'
      }
    }
  ]

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    private eventService: EventService,
    private componentRouter: Router
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    // If we come from detail (back button clicked), we need to redirect to list.
    if (
      this.eventService.previousUrl.includes('/controls/') ||
      this.eventService.previousUrl.includes('/error-api')
    ) {
      return this.componentRouter.navigate(['/controls'])
    }

    this.initCreateEditView()
  }
}
