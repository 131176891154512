<div class="welcome-wrapper">
  <img
    src="../../../../assets/images/error404.svg"
    alt="Error 404"
    width="360"
  />
  <h1 class="title is-1">Error 404: Page not found</h1>
  <p>Maybe this page is private or it does not exist anymore.</p>

  <app-redirection-button
    [role]="currentUser.role.name"
    *ngIf="currentUser"
  ></app-redirection-button>
</div>
