import { NgIf } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import { User } from '../../abacus/interfaces/resources/user.interface'
import { AuthService } from '../../abacus/services/auth.service'

@Component({
  selector: 'app-redirection-button',
  template: `
    <div class="has-position-fixed" *ngIf="redirection">
      <div
        class="is-flex is-flex-direction-column is-align-item-center is-justify-content-center"
      >
        <button
          class="button is-black is-rounded has-icon"
          [routerLink]="redirection.url + '/create'"
        >
          <span class="icon is-small">
            <i class="icon icon-camera"></i>
          </span>
          <span> New {{ redirection.label }}</span>
        </button>
        <a
          [routerLink]="redirection.url"
          class="button is-ghost has-text-dark mt-2"
        >
          Go to {{ redirection.label }} list
        </a>
      </div>
    </div>
  `,
  imports: [RouterLink, NgIf],
  standalone: true
})
export class RedirectionButtonComponent implements OnInit {
  @Input() role: string
  redirection: { url: string; label: string }

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.currentUser.subscribe((userRes: User) => {
      if (
        ['operator', 'advancedOperator', 'analyst', 'admin'].includes(
          userRes.role.name
        )
      ) {
        this.redirection = { url: '/scans', label: 'Scan' }
      } else if (['controller'].includes(userRes.role.name)) {
        this.redirection = { url: '/controls', label: 'Control' }
      } else if (['evaluator', 'reUseAnalyst'].includes(userRes.role.name)) {
        this.redirection = { url: '/evaluations', label: 'Re use evaluation' }
      }
    })
  }
}
