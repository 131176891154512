import { ActionType } from '../../abacus/enums/action-type.enum'
import { YieldType } from '../../abacus/enums/yield-type.enum'
import { User } from '../../abacus/interfaces/resources/user.interface'
import { Yield } from '../../abacus/interfaces/yield.interface'

export const userYields: Yield[] = [
  {
    label: 'Name',
    property: 'name',
    type: YieldType.Text,
    className: 'is-narrow',
    disableOrderBy: true
  },
  {
    label: 'Role',
    property: 'role.displayName',
    type: YieldType.Text
  },
  { label: 'Site', property: 'site.name', type: YieldType.Text },
  {
    label: 'Active',
    property: 'isActive',
    type: YieldType.Switch,
    action: (user: User) => ({
      type: ActionType.Patch,
      patch: {
        path: `/users/${user.id}/toggle-active`,
        successMessage: 'User status has been changed',
        errorMessage: 'Error : could not change user status'
      }
    })
  }
]
