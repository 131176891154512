import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { ResourceService } from '../abacus/services/resource.service'
import { FieldSpecialRule } from '../abacus/interfaces/field-special-rule.interface'
import { AuthService } from '../abacus/services/auth.service'

// Redirect to "go-to-control" certain percentage of times (based on user's site control rate).
@Injectable({
  providedIn: 'root'
})
export class RedirectToControlGuard {
  constructor(
    private router: Router,
    private resourceService: ResourceService,
    private authService: AuthService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    // If the user already has an image, don't redirect to control.
    const specialRules: string = route.queryParams.specialRules

    const parsedSpecialRules: FieldSpecialRule[] = specialRules
      ? JSON.parse(specialRules)
      : []

    const hasImageField: boolean = parsedSpecialRules.some(
      (rule) => rule.fieldId === 'image'
    )

    // If the user have the 'canDoSlowFlow' permission, don't redirect to control.
    const canDoSlowFlow: boolean = await this.authService.can(
      'canDoSlowFlowScans'
    )

    if (!hasImageField && !canDoSlowFlow) {
      const currentUser = await this.resourceService.show('users', 'myself')

      const random = Math.floor(Math.random() * 101)

      if (random < currentUser.site.controlRate) {
        return this.router.navigate(['go-to-control'])
      }
    }

    return true
  }
}
