<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-wrapper">
    <a class="logo" routerLink="/">
      <img src="/assets/images/logo.svg" class="logo--image" style="padding:12px 20px" />
    </a>
  </div>

  <div class="navbar-menu" id="create-dropdown">
    <!-- Breadcrumbs -->
    <case-breadcrumbs></case-breadcrumbs>

    <div class="navbar-end">
      <div class="navbar-item pr-4">
        <div class="buttons mb-0">
          <div class="control mr-6" *ngIf="isStaging">
            <div class="tags has-addons">
              <span class="tag is-dark">Version</span>
              <span class="tag is-warning">Staging</span>
            </div>
          </div>

          <!-- Menu links -->
          <ng-container *ngFor="let link of links">
            <a
              [routerLink]="[link.routePath]"
              [queryParams]="link.queryParams"
              [attr.data-tooltip]="link.label"
              *caseHasPermission="link.permission"
              class="button is-circle is-white mr-5 has-tooltip has-tooltip-left"
            >
              <span class="icon has-text-grey">
                <i class="icon {{ link.icon }} is-size-3"></i>
              </span>
            </a>
          </ng-container>

          <!-- UserMenu dropdown -->
          <div
            class="dropdown is-right"
            [ngClass]="{ 'is-active': showUserMenu }"
            *ngIf="currentUser"
          >
            <div class="dropdown-trigger">
              <a
                aria-haspopup="true"
                class="has-text-dark"
                aria-controls="dropdown-menu2"
                (click)="
                  showUserMenu = !showUserMenu;
                  showLinkMenu = false;
                  showNotificationMenu = false
                "
              >
                <span
                  >{{ currentUser.firstName | slice : 0 : 1 }}.{{
                    currentUser.lastName
                  }}</span
                >
                <span class="icon is-small">
                  <i class="icon icon-chevron-down"></i>
                </span>
              </a>
            </div>
            <div class="dropdown-menu is-small" role="menu">
              <div class="dropdown-content">
                <a
                  [routerLink]="['/users', currentUser.id, 'edit']"
                  class="dropdown-item"
                >
                  {{ currentUser.firstName }} {{ currentUser.lastName }}
                </a>
                <hr class="dropdown-divider" />
                <a routerLink="/logout" class="dropdown-item"> Sign out </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
