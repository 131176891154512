import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { User } from '../../abacus/interfaces/resources/user.interface'
import { AuthService } from '../../abacus/services/auth.service'
import { FlashMessageService } from '../../abacus/services/flash-message.service'
import { UploadService } from '../../abacus/services/upload.service'

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  @ViewChild('video') video: ElementRef

  snapshot: string
  loading: boolean

  cameras: { id: string; label: string }[] = []
  selectedCameraId: string

  isCameraSelectionOpen: boolean = false

  imageSize = 1000

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private flashMessageService: FlashMessageService,
    private uploadService: UploadService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.openCamera()
  }

  ngOnDestroy() {
    this.stopExistingVideoStream()
  }

  getCameras(): Promise<{ id: string; label: string }[]> {
    return navigator.mediaDevices
      .enumerateDevices()
      .then((devices: MediaDeviceInfo[]) =>
        devices
          .filter((device: MediaDeviceInfo) => device.kind === 'videoinput')
          .filter((device: MediaDeviceInfo) => device.label !== '')
          .map((device: MediaDeviceInfo) => ({
            id: device.deviceId,
            label: device.label
          }))
      )
  }

  async openCamera() {
    // Stop any existing video stream.
    this.stopExistingVideoStream()

    // Common constraints for all cameras.
    const constraints: MediaStreamConstraints = {
      video: {
        width: {
          ideal: this.imageSize
        },
        height: {
          ideal: this.imageSize
        }
      }
    }

    // We get favorite camera from localStorage.
    if (localStorage.getItem('favoriteCamera')) {
      this.selectedCameraId = localStorage.getItem('favoriteCamera')
    } else {
      // Or we take the back camera by default.
      this.selectedCameraId = 'environmentDefault'
      localStorage.setItem('favoriteCamera', this.selectedCameraId)
    }

    // Default cameras rely on facingMode.
    if (this.selectedCameraId?.includes('Default')) {
      ;(constraints.video as MediaTrackConstraints).facingMode =
        this.selectedCameraId.replace('Default', '')
    } else {
      // Otherwise, we use a an exact deviceId.
      ;(constraints.video as MediaTrackConstraints).deviceId = {
        exact: this.selectedCameraId
      }
    }

    console.log(constraints)

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(async (stream) => {
        this.video.nativeElement.srcObject = stream

        // We get cameras after the stream is open as calling getUserMedia() twice causes an error on Samsung devices.
        this.cameras = await this.getCameras()
        console.log(this.cameras)
      })
      .catch((error) => {
        this.flashMessageService.error(
          `There was an error opening your camera. Ensure that you have the right permissions. Error code: ${error}`
        )
      })
  }

  takeSnapshot() {
    const width = this.imageSize
    const height = this.imageSize

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    let context = canvas.getContext('2d')
    context.drawImage(this.video.nativeElement, 0, 0, width, height)

    this.snapshot = canvas.toDataURL('image/jpeg', 1)

    setTimeout(() => {
      const img: HTMLImageElement = document.getElementById(
        'preview'
      ) as HTMLImageElement

      img.src = this.snapshot
    })
  }

  validateSnapshot() {
    this.uploadService
      .uploadImage('snapshot', this.dataURLtoFile(this.snapshot))
      .subscribe(
        (res: { path: string }) => {
          this.loading = false

          this.router.navigate(
            [this.activatedRoute.snapshot.queryParams.redirectTo],
            {
              queryParams: {
                specialRules: JSON.stringify([
                  {
                    fieldId: 'image',
                    forcedValue: res.path,
                    hidden: true
                  }
                ])
              }
            }
          )
        },
        (err) => {
          this.loading = false
          this.flashMessageService.error(
            'There was an error uploading your image.'
          )
        }
      )
  }

  deleteSnapshot() {
    this.snapshot = null
    setTimeout(() => this.openCamera())
  }

  backToHome() {
    this.authService.currentUser.subscribe((userRes: User) => {
      let backPath: string

      if (
        ['operator', 'advancedOperator', 'analyst', 'admin'].includes(
          userRes.role.name
        )
      ) {
        backPath = '/scans'
      } else if (['controller'].includes(userRes.role.name)) {
        backPath = '/controls'
      } else if (['evaluator', 'reUseAnalyst'].includes(userRes.role.name)) {
        backPath = '/evaluations'
      }
      this.router.navigate([backPath])
    })
  }

  openCameraSelection() {
    console.log('openCameraSelection')
    let el: HTMLElement = document.getElementById('camera-selection')
    el.click()
  }

  changeCamera(selectedCameraId: string) {
    localStorage.setItem('favoriteCamera', selectedCameraId)

    // Reload the page to open the new camera. This is a workaround for some devices that do not change the camera on the fly.
    window.location.reload()
  }

  private stopExistingVideoStream(): void {
    if (this.video?.nativeElement.srcObject) {
      const stream = this.video.nativeElement.srcObject as MediaStream
      stream.getTracks().forEach((track) => track.stop())

      this.video.nativeElement.srcObject = null
    }
  }

  private dataURLtoFile(dataUrl: string) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], 'snapshot', { type: mime })
  }
}
