import { ActionType } from '../../abacus/enums/action-type.enum'
import { LinkType } from '../../abacus/enums/link-type.enum'
import { ResourceDefinition } from '../../abacus/interfaces/resource-definition.interface'

export const brandDefinition: ResourceDefinition = {
  title: 'Brands',
  nameSingular: 'brand',
  namePlural: 'brands',
  className: 'Brand',
  mainIdentifier: 'name',
  slug: 'brands',
  path: 'brands',
  icon: 'icon-grid',
  hasDetailPage: false,
  hasListPage: true,
  buttons: [LinkType.CREATE, LinkType.EXPORT],
  defaultLink: LinkType.EDIT,
  childrenThatPreventDelete: [],
  dropdownLinks: [
    {
      label: 'Edit',
      permission: 'editBrands',
      action: (brand) => ({
        type: ActionType.Link,
        link: {
          path: `${brandDefinition.path}/${brand.id}/edit`
        }
      })
    },
    {
      label: 'Delete',
      permission: 'deleteBrands',
      action: (brand) => ({
        type: ActionType.Delete,
        delete: {
          itemToDelete: brand,
          definition: brandDefinition
        }
      }),
      disabled: (brand) => brand.modelsCount > 0
    }
  ]
}
