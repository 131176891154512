<div id="stream-wrapper">
  <ng-container *ngIf="!snapshot">
    <div class="has-media">
      <video autoplay playsinline #video></video>
    </div>
    <div
      class="is-flex is-align-items-flex-end is-justify-content-center button-box pb-6"
    >
      <button class="button-snapshot" (click)="takeSnapshot()"></button>
      <button
        (click)="backToHome()"
        class="button is-ghost ghost back-button has-text-white"
      >
        <i class="icon icon-chevron-left is-size-3"></i>
      </button>
      <button
        (click)="isCameraSelectionOpen = true"
        class="button is-ghost ghost camera-settings has-text-white"
      >
        <i class="icon icon-settings is-size-3"></i>
      </button>
      <div *ngIf="isCameraSelectionOpen" class="modal-camera-settings">
        <div class="columns">
          <div class="column is-12">
            <button
              (click)="isCameraSelectionOpen = false"
              class="button is-ghost ghost has-text-white"
            >
              <i class="icon icon-chevron-left is-size-3"></i>
              <span>Camera settings</span>
            </button>
            <div class="card has-background-grey-darker mx-4 pb-4">
              <div class="card-content"></div>
              <form action="" class="px-4">
                <label for="camera-selection" class="has-text-white">
                  Select your camera
                </label>
                <div class="control">
                  <div class="select is-fullwidth is-dark">
                    <select
                      id="camera-selection"
                      name="camera-selection"
                      (change)="changeCamera($event.target.value)"
                      *ngIf="cameras"
                    >
                      <!-- Special values: environment default and user default -->
                      <option
                        value="environmentDefault"
                        [selected]="selectedCameraId === 'environmentDefault'"
                      >
                        Default back camera
                      </option>
                      <option
                        value="userDefault"
                        [selected]="selectedCameraId === 'userDefault'"
                      >
                        Default user camera
                      </option>
                      <option
                        *ngFor="let camera of cameras; let i = index"
                        [value]="camera.id"
                        [selected]="selectedCameraId === camera.id"
                      >
                        {{ camera.label || camera.id || 'Camera ' + i }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="snapshot">
    <div class="has-media">
      <img src="" alt="snapshot preview" id="preview" />
    </div>
    <div
      class="is-flex is-align-items-flex-end is-justify-content-space-between button-box pb-6"
    >
      <button
        class="button is-dark is-circle is-large"
        (click)="deleteSnapshot()"
      >
        <i class="icon icon-x is-size-3"></i>
      </button>

      <button
        class="button is-dark is-circle is-large"
        (click)="validateSnapshot()"
      >
        <i class="icon icon-check3 is-size-3"></i>
      </button>
    </div>
  </ng-container>

  <div class="loader" *ngIf="loading">
    <div class="is-loading-page">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <style>
          .spinner_P7sC {
            transform-origin: center;
            animation: spinner_svv2 0.75s infinite linear;
          }
          @keyframes spinner_svv2 {
            100% {
              transform: rotate(360deg);
            }
          }
        </style>
        <path
          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
          class="spinner_P7sC"
        />
      </svg>
    </div>
  </div>
</div>
