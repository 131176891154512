<ng-container *ngIf="address && address.name">
  <a
    target="_blank"
    class="has-text-black"
    href="http://maps.google.com/?q={{ address.streetNumber }}+{{
      address.route
    }}+{{ address.postalCode }}+{{ address.locality }}+{{
      address.department
    }}+{{ address.region }}+{{ address.country }}"
  >
    <ng-container *ngIf="address.streetNumber"
      >{{ address.streetNumber }}&nbsp;</ng-container
    >
    <ng-container *ngIf="address.route"
      >{{ address.route }},&nbsp;</ng-container
    >
    <ng-container *ngIf="address.postalCode"
      >{{ address.postalCode }}&nbsp;</ng-container
    >
    <ng-container *ngIf="address.locality">{{ address.locality }}</ng-container>
    <ng-container
      *ngIf="
        !address.streetNumber &&
        !address.route &&
        !address.postalCode &&
        !address.locality
      "
      >{{ address.name }}</ng-container
    >
  </a>
</ng-container>
