import { Component, OnInit } from '@angular/core'
import { UntypedFormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { CaseCreateEditComponent } from '../../../abacus/components/case-create-edit.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { Field } from '../../../abacus/interfaces/field.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseCreateEditTemplate } from '../../../abacus/templates/case-create-edit.template'
import { siteDefinition } from '../site.definition'

@Component({
  selector: 'app-site-create-edit',
  template: caseCreateEditTemplate
})
export class SiteCreateEditComponent
  extends CaseCreateEditComponent
  implements OnInit
{
  definition: ResourceDefinition = siteDefinition
  fields: Field[] = [
    {
      label: 'name',
      property: 'name',
      required: true,
      inputType: InputType.Text
    },
    {
      label: 'Max age (in years)',
      property: 'maxAge',
      required: true,
      inputType: InputType.Number,
      min: 0
    },
    {
      label: 'control rate (in %)',
      property: 'controlRate',
      required: true,
      inputType: InputType.Number,
      min: 0,
      max: 100
    }
  ]

  showLoaderInsteadOfForm = false

  constructor(
    formBuilder: UntypedFormBuilder,
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService
  ) {
    super(
      formBuilder,
      router,
      breadcrumbService,
      resourceService,
      flashMessageService,
      activatedRoute
    )
  }

  ngOnInit() {
    this.initCreateEditView()
  }
}
