import { Component, Input } from '@angular/core'

import { environment } from '../../../../../environments/environment'

@Component({
  selector: 'case-image-yield',
  templateUrl: './image-yield.component.html',
  styleUrls: ['./image-yield.component.scss']
})
export class ImageYieldComponent {
  @Input() image: string
  @Input() defaultImage = '/assets/images/avatar.svg'
  @Input() label: string

  storagePath: string = environment.storagePath
}
