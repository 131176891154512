<div class="control">
  <label class="toggle" for="toggle-input-{{ uniqueId }}"
    >{{ label }}<span class="has-text-danger" *ngIf="required">*</span>
  </label>

  <div class="control">
    <div class="field">
      <input
        type="checkbox"
        class="switch is-rounded"
        (change)="onChange()"
        [checked]="checked"
        id="toggle-input-{{ uniqueId }}"
      />
      <label for="toggle-input-{{ uniqueId }}">{{ placeholder }}</label>
    </div>
  </div>
  <p class="help" *ngIf="helpText">{{ helpText }}</p>
</div>
