export const environment = {
  production: true,
  envName: 'production',
  appName: 'ORE',
  baseUrl: 'https://preprod.panel.app.terra.coop',
  apiBaseUrl: 'https://preprod.api.panel.app.terra.coop',
  storagePath: 'https://preprod.api.panel.app.terra.coop/storage',
  tokenName: 'ORE.PREPROD',
  tokenAllowedDomains: ['preprod.api.panel.app.terra.coop'],
  enableBugsnag: true,
  bugsnagApiKey: 'c7ea7b4604c9cc45807dff7dc2f53874',
  isOnboarding: false,
  googlePlacesAPIKey: 'Insert API Key Here'
}
