import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { RouterModule } from '@angular/router'
import Bugsnag from '@bugsnag/js'

import { environment } from '../environments/environment'
import { AbacusModule } from './abacus/abacus.module'
import { AuthService } from './abacus/services/auth.service'
import { EventService } from './abacus/services/event.service'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CameraComponent } from './pages/camera/camera.component'
import { ErrorApiComponent } from './pages/error-api/error-api.component'
import { HomeComponent } from './pages/home/home.component'
import { RedirectionButtonComponent } from './partials/redirection-button/redirection-button.component'
import { TouchMenuComponent } from './partials/touch-menu/touch-menu.component'
import { BrandCreateEditComponent } from './resources/brand/brand-create-edit/brand-create-edit.component'
import { BrandListComponent } from './resources/brand/brand-list/brand-list.component'
import { ControlCreateEditComponent } from './resources/control/control-create-edit/control-create-edit.component'
import { ControlDetailComponent } from './resources/control/control-detail/control-detail.component'
import { ControlListComponent } from './resources/control/control-list/control-list.component'
import { GoToControlComponent } from './resources/control/go-to-control/go-to-control.component'
import { ErrorListComponent } from './resources/error/error-list/error-list.component'
import { EvaluationCreateEditComponent } from './resources/evaluation/evaluation-create-edit/evaluation-create-edit.component'
import { EvaluationDetailComponent } from './resources/evaluation/evaluation-detail/evaluation-detail.component'
import { EvaluationListComponent } from './resources/evaluation/evaluation-list/evaluation-list.component'
import { ModelCreateEditComponent } from './resources/model/model-create-edit/model-create-edit.component'
import { ModelListComponent } from './resources/model/model-list/model-list.component'
import { ScanCreateEditComponent } from './resources/scan/scan-create-edit/scan-create-edit.component'
import { ScanDetailComponent } from './resources/scan/scan-detail/scan-detail.component'
import { ScanListComponent } from './resources/scan/scan-list/scan-list.component'
import { SiteCreateEditComponent } from './resources/site/site-create-edit/site-create-edit.component'
import { SiteListComponent } from './resources/site/site-list/site-list.component'
import { UserCreateEditComponent } from './resources/user/user-create-edit.component'
import { UserListComponent } from './resources/user/user-list.component'

if (environment.enableBugsnag) {
  Bugsnag.start(environment.bugsnagApiKey)
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserListComponent,
    UserCreateEditComponent,
    ScanCreateEditComponent,
    ScanListComponent,
    ScanDetailComponent,
    ErrorListComponent,
    BrandCreateEditComponent,
    BrandListComponent,
    SiteCreateEditComponent,
    SiteListComponent,
    ControlCreateEditComponent,
    ControlListComponent,
    ControlDetailComponent,
    EvaluationCreateEditComponent,
    EvaluationListComponent,
    EvaluationDetailComponent,
    ModelCreateEditComponent,
    ModelListComponent,
    CameraComponent,
    GoToControlComponent,
    TouchMenuComponent,
    ErrorApiComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    AbacusModule,

    // Standalone.
    RedirectionButtonComponent
  ],
  providers: [EventService, AuthService],
  // providers: [{ provide: ErrorHandler, useFactory: errorHandlerFactory }],
  bootstrap: [AppComponent]
})
export class AppModule {}
