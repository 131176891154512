<div class="card is-radiusless">
  <div class="card-content">
    <div class="content">
      <h3 class="title is-3">⚠️ Technical error</h3>
      <p class="is-size-5">You can try to repeat this operation.</p>
      <p class="is-size-5">
        If the error persists, please contact your superior.
      </p>
    </div>
  </div>
</div>
<app-redirection-button></app-redirection-button>
