import { Route } from '@angular/router'

import { ResourceMode } from '../../abacus/enums/resource-mode.enum'
import { AuthGuard } from '../../abacus/guards/auth.guard'
import { PermissionGuard } from '../../abacus/guards/permission.guard'
import { SiteCreateEditComponent } from './site-create-edit/site-create-edit.component'
import { SiteListComponent } from './site-list/site-list.component'

export const siteRoutes: Route[] = [
  {
    path: 'sites',
    component: SiteListComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      permission: 'browseSites'
    }
  },
  {
    path: 'sites/create',
    component: SiteCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Create,
      permission: 'addSites'
    }
  },
  {
    path: 'sites/:id/edit',
    component: SiteCreateEditComponent,
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      mode: ResourceMode.Edit,
      permission: 'editSites'
    }
  }
]
