import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { CaseListComponent } from '../../../abacus/components/case-list.component'
import { InputType } from '../../../abacus/enums/input-type.enum'
import { Filter } from '../../../abacus/interfaces/filter.interface'
import { ResourceDefinition } from '../../../abacus/interfaces/resource-definition.interface'
import { Yield } from '../../../abacus/interfaces/yield.interface'
import { AuthService } from '../../../abacus/services/auth.service'
import { BreadcrumbService } from '../../../abacus/services/breadcrumb.service'
import { FilterService } from '../../../abacus/services/filter.service'
import { FlashMessageService } from '../../../abacus/services/flash-message.service'
import { ResourceService } from '../../../abacus/services/resource.service'
import { caseListTemplate } from '../../../abacus/templates/case-list.template'
import { brandDefinition } from '../../brand/brand.definition'
import { modelDefinition } from '../model.definition'

@Component({
  selector: 'app-model-list',
  styleUrls: ['./model-list.component.scss'],
  template: caseListTemplate
})
export class ModelListComponent extends CaseListComponent implements OnInit {
  definition: ResourceDefinition = modelDefinition
  yields: Yield[] = [
    {
      label: 'name',
      property: 'name'
    },
    {
      label: 'brand',
      property: 'brand.name'
    },
    {
      label: 'scans',
      property: 'scansCount',
      disableOrderBy: true
    },
    {
      label: 'Re use Evaluations',
      property: 'evaluationsCount',
      disableOrderBy: true
    },
    {
      label: 'controls',
      property: 'controlsCount',
      disableOrderBy: true
    }
  ]

  filters: Filter[] = [
    {
      label: 'Brand',
      className: 'is-4',
      inputType: InputType.MultiSearch,
      searchResources: [brandDefinition],
      properties: {
        brandIds: 'brandIds'
      }
    }
  ]

  constructor(
    router: Router,
    activatedRoute: ActivatedRoute,
    resourceService: ResourceService,
    breadcrumbService: BreadcrumbService,
    flashMessageService: FlashMessageService,
    authService: AuthService,
    filterService: FilterService
  ) {
    super(
      router,
      activatedRoute,
      breadcrumbService,
      resourceService,
      flashMessageService,
      authService,
      filterService
    )
  }

  ngOnInit() {
    this.initListView()
  }
}
