<div
  class="is-yield-file-icon is-flex is-align-items-center is-justify-content-center"
  *ngIf="mime === FileMime.Pdf"
>
  <img src="./assets/images/file-pdf.svg" alt="" />
</div>
<div
  class="is-yield-file-icon is-flex is-align-items-center is-justify-content-center"
  *ngIf="mime === FileMime.Word"
>
  <img src="./assets/images/file-doc.svg" alt="" />
</div>
<div
  class="is-yield-file-icon is-flex is-align-items-center is-justify-content-center"
  *ngIf="mime === FileMime.Excel"
>
  <img src="./assets/images/file-xls.svg" alt="" />
</div>
<div
  class="is-yield-file-icon is-flex is-align-items-center is-justify-content-center"
  *ngIf="mime === FileMime.Ppt"
>
  <img src="./assets/images/file-ppt.svg" alt="" />
</div>
<div
  class="is-yield-file-icon is-flex is-align-items-center is-justify-content-center"
  *ngIf="mime === FileMime.Other"
>
  <img src="./assets/images/file-other.svg" alt="" />
</div>
